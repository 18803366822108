<template>
  <tableForm :value="data" :option="tableOption">
    <template #menu="{ row }">
      <viewProduct :value="row"></viewProduct>
    </template>
  </tableForm>
</template>

<script>
import { tableOption } from './const'
import tableForm from '@/views/components/base/tableForm'
import viewProduct from './module/viewProduct'
import { cloneDeep, map } from 'lodash'
import { getLabel } from '@/components/avue/utils/util'
import { findFixedLevelItemId, getLevelCategoryList } from '@/views/product/utils'
import { fixedLevelBatchSet } from '@/api/accountApi'

export default {
  components: {
    tableForm,
    viewProduct
  },
  props: {
    value: {
      default: () => []
    },
    mainAccountUid: Number | String
  },
  data() {
    return {
      data: [],
      tableDic: {}
    }
  },
  computed: {
    tableOption() {
      return {
        ...tableOption,
        dic: this.tableDic
      }
    }
  },
  watch: {
    value: {
      handler(value) {
        value = cloneDeep(value)
        const levelId = value?.[0]?.productPrototypePlatform?.levelCategoryId
        let tempObj = {}
        value.forEach((item) => {
          if (!item) return

          const levelCategoryId = item.productPrototypePlatform?.levelCategoryId
          if (!tempObj[levelCategoryId]) tempObj[levelCategoryId] = []

          tempObj[levelCategoryId].push(item)
        })
        let { tableDic } = this
        let data = []
        for (const levelCategoryId in tempObj) {
          tableDic[levelCategoryId] = getLevelCategoryList(tempObj[levelCategoryId][0].sizeList)
          data.push({
            levelId,
            levelName: getLabel('gearsCategory', levelId),
            levelCategoryId: Number(levelCategoryId),
            levelCategoryName: getLabel('gearsCategory', levelCategoryId),
            // 固定档位回显：无固定档位或档位改变时，显示第一个档位
            levelItemId: findFixedLevelItemId(
              tableDic[levelCategoryId],
              tempObj[levelCategoryId].find((item) => item.fixedLevelCategoryConfigId)?.fixedLevelCategoryConfigId
            ),
            productCount: tempObj[levelCategoryId].length,
            productList: tempObj[levelCategoryId],
            selectionData: tempObj[levelCategoryId]
          })
        }
        this.data = data
      },
      immediate: true
    }
  },
  methods: {
    async onsubmit() {
      let list = this.data.map((item) => ({
        levelCategoryId: item.levelCategoryId,
        levelCategoryItemId: item.levelItemId,
        prototypeIdList: map(item.selectionData, 'id')
      }))

      let res = await awaitResolveLoading(
        fixedLevelBatchSet({
          list,
          mainAccountUid: this.mainAccountUid
        })
      )
      if (!res) return

      this.$message.success('操作成功')
      this.$emit('success')
    }
  }
}
</script>

<style lang="scss" scoped>
.el-radio {
  width: 100%;
  margin-bottom: 24px;
}
</style>
