export const tableOption = {
  delBtn: false,
  column: [
    {
      label: '档位分类',
      prop: 'levelCategoryName'
    },
    {
      label: '固定的价格',
      prop: 'levelCategoryId',
      children: [
        {
          prop: 'levelItemId',
          type: 'select',
          clearable: false,
          dicData: 'levelCategoryId'
        }
      ]
    }
  ]
}