<template>
  <el-form
    ref="form"
    class="validate-form-self"
    :class="{ 'is-edit': !disabled }"
    :style="{ width: setPx(option.width) }"
    :model="form"
    :label-width="setPx(option.labelWidth, 80)"
    :label-suffix="labelSuffix"
    :validate-on-rule-change="validData(option.validateOnRuleChange, false)"
    :hide-required-asterisk="option.hideRequiredAsterisk"
    @submit.native.prevent
    @validate="handleValidate"
  >
    <el-table
      ref="table"
      border
      class="table-border"
      :class="{ 'not-empty-text': !hasEmptyText }"
      :data="form.tableData"
      :row-key="option.rowKey"
      v-on="$listeners"
    >
      <!-- 选择框 -->
      <template v-if="option.selection">
        <el-table-column
          type="selection"
          width="50"
          align="center"
          :selectable="option.selectable"
          :reserve-selection="option.reserveSelection"
        ></el-table-column>
      </template>

      <el-table-column
        v-for="column in columnOption"
        :key="column.prop"
        v-bind="column"
      >
        <template #default="scoped">
          <slot :name="column.prop" v-bind="scoped">
            <template v-if="!column.disabled && column.children">
              <el-form-item
                v-for="item in column.children || []"
                :key="`${item.prop}${form.tableData[scoped.$index].$key}`"
                :label="item.label"
                :prop="`tableData[${scoped.$index}].${item.prop}`"
                :rules="item.rules"
                :label-width="setPx(validData(item.labelWidth, option.labelWidth, 0))"
                :show-message="validData(item.showMessage, false)"
              >
                <component
                  :is="getComponent(item.type)"
                  v-model="form.tableData[scoped.$index][item.prop]"
                  v-bind="item"
                  :dic="getDic(item, scoped.row)"
                  :disabled="getDisabled(item, scoped.row)"
                  :size="validData(item.size, $formOptions.size)"
                  @input="oninput"
                  @change="oninput"
                ></component>
              </el-form-item>
            </template>
            <template v-else>{{ scoped.row[column.prop] }}</template>
          </slot>
        </template>
      </el-table-column>
      <el-table-column
        v-if="!disabled && validData(option.menu, true)"
        label="操作"
        :fixed="option.menuFixed"
        :align="validData(option.menuAlign)"
        :header-align="option.menuHeaderAlign"
        :width="validData(option.menuWidth, 240)"
      >
        <template #default="scoped">
          <slot name="menu" v-bind="scoped"></slot>
          <Popover
            v-if="validData(option.delBtn, true)"
            :disabled="!(canDeleteOnRemainingOne || form.tableData.length > 1)"
            @sureHandler="delTableData(scoped.$index)"
          >
            <template #tip>
              <p>确认删除产品？</p>
            </template>
            <template #reference="{ scope: loading }">
              <el-button type="text">删除</el-button>
            </template>
          </Popover>
        </template>
      </el-table-column>
    </el-table>
  </el-form>
</template>

<script>
import emitter from 'element-ui/src/mixins/emitter'
import { componentMethodsMixin } from '@/mixins'
import { vaildData } from '@/components/avue/utils/util'
import { setPx, validData } from '@/components/avue/utils/util'
import { cloneDeep } from 'lodash'

export default {
  mixins: [
    emitter,
    componentMethodsMixin(
      'table',
      [
        'toggleRowSelection',
        'clearSelection'
      ]
    )
  ],
  props: {
    option: {
      default: () => ({})
    },
    value: {
      default: () => []
    },
    prop: {
      default: 'tableData'
    },
    disabled: Boolean,
    hasEmptyText: {
      default: true
    },
    canDeleteOnRemainingOne: {
      default: true
    }
  },
  data() {
    return {
      validateMessages: {},
      form: {}
    }
  },
  computed: {
    columnOption({ option: { column, disabled } }) {
      disabled = validData(disabled, this.disabled)
      return cloneDeep(column).filter(item => {
        item.disabled = validData(item.disabled, disabled)
        return !item.hide
      })
    },
    defaultRow({ columnOption }) {
      let tempObj = {}
      columnOption.forEach(column => {
        column.children?.forEach(item => {
          tempObj[item.prop] = ''
        })
      })
      return tempObj
    },

    labelSuffix({ option: { labelSuffix } }) {
      if (typeof labelSuffix === 'boolean') return labelSuffix ? '：' : ''
      return vaildData(labelSuffix, '：')
    }
  },
  watch: {
    value: {
      handler(value) {
        this.$set(this.form, 'tableData', Array.isArray(value) ? value : [])
      },
      immediate: true
    }
  },
  methods: {
    addTableData(row = cloneDeep(this.defaultRow)) {
      row.$key = +new Date()
      this.form.tableData.push(row)
      this.oninput()
    },
    delTableData(index) {
      this.form.tableData.splice(index, 1)
      this.oninput()
    },

    oninput() {
      this.$emit('input', this.form.tableData)

      this.dispatch('ElFormItem', 'el.form.change')
    },

    handleValidate(prop, valid, msg) {
      // console.log(prop, valid, msg)

      let { validateMessages } = this
      validateMessages[prop] = valid || msg
      if (valid) {
        for (const key in validateMessages) {
          if (validateMessages[key] !== true) msg = validateMessages[key]
        }
      }

      this.$emit('validate', this.prop, msg)
    },
    async validate(callback) {
      return this.$refs.form.validate(callback)
    },

    // 选中实例
    toggleSelection(rows, selected) {
      if (rows) {
        rows.forEach((row) => {
          this.toggleRowSelection(row, selected)
        })
      } else {
        this.clearSelection()
      }
    },

    getDic({ dicData, dicType, prop }, row) {
      let dic = this.option.dic || {}
      return validData(
        dic[dicData], dic[dicType], dic[prop], dic[row[dicData]],
        typeof dicData === 'string' ? undefined : dicData,
        undefined
      )
    },
    getDisabled({ disabled }, row) {
      if (typeof disabled === 'function') return disabled(row)
      return disabled
    },
    getComponent(type) {
      let component = {
        select: 'avueCrudSelect'
      }[type]
      return component || 'avueCrudInput'
    },
    setPx,
    validData
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .not-empty-text .el-table__empty-block {
    display: none;
  }

  .el-table.el-table--border.table-border {
    border-left: 1px solid $border-color;
    &::after {
      display: block;
    }
    th {
      border-right: none;
    }
  }
  th {
    height: 35px;
    padding: 0;
  }
  td {
    height: 53px;
  }
  .is-disabled {
    .el-button--text {
      color: #CCCCCC;
    }
  }

  &.is-edit {
    td {
      padding: 0;
    }
  }

  .el-form-item {
    margin-bottom: 0;
  }
  .el-form-item__content {
    line-height: 52px;
  }
}
</style>
